<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import service from '../../../server/http_service'
import global_func from '@/global_function'

export default {
  page: {
    title: 'Contact', // title in browser tab
    meta: [{ name: 'description' }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      /// page info
      title: 'Contact Management', // title in page tab
      items: [
        {
          text: 'Contact', // page path
          href: '/Contact',
        },
        {
          text: 'ShowData', // page path
          active: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      //  data
      dataContact: [],
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'จัดการข้อมูล',
          class: 'text-center',
        },
        {
          key: 'full_name',
          label: 'ชื่อ-นามสกุล',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'mobile_phone_number',
          label: 'เบอร์โทรศัทพ์',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'email',
          label: 'อีเมล',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'job_title',
          label: 'ตำแหน่ง',
          sortable: true,
          class: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    showConfirm(id, index, name) {
      this.tmp_id = id
      this.tmp_index = index
      this.msgShow = ' ลบข้อมูล : ' + name.first_name + ' ' + name.last_name
      global_func.askAction('คุณต้องการลบข้อมูลใช่หรือไม่', this.msgShow, 'warning').then(res => {
        if (res == 'yes') {
          this.deleteData(id, index)
        } else {
          console.log('close')
        }
      })
    },
    deleteData(id, index) {
      service
        .postService('deleteContact', {
          id: id,
        })
        .then(rp => {
          console.log(rp)
          var icon_type = ''
          if (rp.code == '200') {
            var realIndex = index
            if (this.currentPage > 1) {
              var rows = this.perPage * (this.currentPage - 1)
              realIndex = index + rows
            }
            this.dataContact.splice(realIndex, 1)
            this.totalRows = this.dataContact.length
            this.msgShow = 'บันทึกข้อมูลสำเร็จ'
            icon_type = 'success'
          } else {
            this.msgShow = 'บันทึกข้อมูลไม่สำเร็จ'
            icon_type = 'error'
          }
          global_func.alertMessage(this.msgShow, '', icon_type)
        })
        .catch(error => {
          global_func.alertMessage('บันทึกข้อมูลไม่สำเร็จ', '', 'error')
          console.log(error)
        })
    },
    getData() {
      console.log('get data from url')
      var parameter = {
        company_id: '1',
        contact_type_id: '1,2',
      }
      // service.getService('getContact', parameter).then(rp => {
      //   this.dataContact = rp.data
      //   this.totalRows = this.dataContact.length
      // })
      service.postService('getContact', parameter).then(rp => {
        this.dataContact = rp.data
        this.totalRows = this.dataContact.length
      })
    },
    editData(id) {
      this.$router.push({ path: '/contact/edit', query: { id: id } })
      // var obj = {
      //   id : id,
      //   page : "home"
      // }
      // this.$router.push({ name: 'Contact-edit', params: { objParam: obj } })
    },
    infoData(id) {
      // var obj = {
      //   id : id,
      //   page : "detail"
      // }
      // this.$router.push({ name: 'Contact-edit', params: { objParam: obj } })
      this.$router.push({ path: '/contact/edit', query: { id: id, page: 'detail' } })
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-container fluid style="margin-top:2%">
      <b-row>
        <b-col lg="4" class="my-1">
          <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="3" label-cols-md="2" label-cols-lg="2" label-align-sm="right" label-size="sm" class="mb-0">
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm" />
          </b-form-group>
        </b-col>
        <b-col lg="3" />
        <b-col lg="4" class="my-1">
          <b-form-group label="Filter" label-for="filter-input" label-cols-sm="5" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" />

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="1" class="my-1 text-right">
          <b-button size="sm" variant="success" @click="$router.push('/Contact/add')">
            เพิ่มลูกค้า
          </b-button>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-row />

      <b-table striped hover :items="dataContact" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered">
        <template #cell(full_name)="row"> {{ row.item.first_name + ' ' + row.item.last_name }}</template>

        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(actions)="row">
          <b-button size="sm" class="mr-1" @click="infoData(row.item.contact_id)">
            แสดงข้อมูลทั้งหมด
          </b-button>
          <!-- <router-link :to="{ name: 'Contact-edit', params: { id: row.item.contact_id } }">
              <b-button size="sm" class="mr-1" variant="warning">
                แก้ไข
              </b-button>
            </router-link> -->
          <b-button size="sm" class="mr-1" variant="warning" @click="editData(row.item.contact_id)">
            แก้ไข
          </b-button>

          <b-button size="sm" variant="danger" @click="showConfirm(row.item.contact_id, row.index, row.item)">
            ลบข้อมูล
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col sm="8" md="10" />
        <b-col sm="4" md="2" class="my-1">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" />
        </b-col>
      </b-row>

      <!-- Info modal -->
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </b-container>
  </Layout>
</template>
